import { render, staticRenderFns } from "./section4.vue?vue&type=template&id=1856eabb&scoped=true&"
import script from "./section4.vue?vue&type=script&lang=js&"
export * from "./section4.vue?vue&type=script&lang=js&"
import style0 from "./section4.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./section4.vue?vue&type=style&index=1&id=1856eabb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1856eabb",
  null
  
)

export default component.exports