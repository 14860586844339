export default {
  address: "台北市大安區敦化南路一段177巷49號",
  googleSrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d268.66670233779007!2d121.55165906551639!3d25.043104069670306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abc450e4e9cd%3A0xc03c218eac66463e!2zMTA25Y-w5YyX5biC5aSn5a6J5Y2A5pWm5YyW5Y2X6Lev5LiA5q61MTc35be3NDnomZ8!5e0!3m2!1szh-TW!2stw!4v1630729532445!5m2!1szh-TW!2stw",
  googleLink: "https://goo.gl/maps/qH29xmo6rF7e9HMC9",
  phone: "02-2772-6688",
  fbLink:
    "https://www.facebook.com/%E5%A4%AA%E5%B9%B3%E6%B4%8B%E6%95%A6%E5%8D%97%E9%BA%97%E8%88%8D-105748701844313",
  fbMessage: "https://m.me/105748701844313/",
  caseName: "敦南麗舍",
  indigatorLength: 10,
  houseInfos: [
    ["投資興建", "太平洋建設股份有限公司（2506）"], 
    ["建築設計", "葉日明建築師事務所"], 
    ["立面美學", "陳克聚建築師事務所"], 
    ["結構設計", "周有結土木結構技師事務所"], 
    ["公設景觀", "如榆建築及設計事務所"], 
    ["綠建築・智慧建築", "楊謙柔博士"], 
    ["建築代銷", "聯碩地產股份有限公司"], 
    ["建案位置", "台北市大安區敦化南路一段177巷49號"], 
    ["建照號碼", "110建字第0102號"], 
    ["不動產經紀人", "周宗盟(108)南市字第00692號"], 
  ],

  gtmCode: ["KDTGCF2"], // 可放置多個
  recaptcha_site_key_v2: "6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz",
  recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
  recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
  order: {
    title: "預約賞屋",
    subTitle: "東區核心重逢太平洋精品之作<br>不必晝想夜夢 只須及早把握"
  }
};
