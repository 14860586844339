export default [
  {
    name: "來電洽詢",
    //iconSrc:xrequire("@/assets/img/nav/1.png"),
    imgSrc: "",
    subTitle: "",
    section: "contact-info",
    svgSrc: "",
    offset: -600,
    mobileOffset: 0
  },
  //{
  //  name: "立即預約",
  //  //iconSrc:xrequire("@/assets/img/nav/1.png"),
  //  imgSrc: "",
  //  subTitle: "",
  //  section: "order-top",
  //  svgSrc: "",
  //  offset: -50,
  //  mobileOffset: 0
  //},
  {
    name: "地圖導航",
    //iconSrc:xrequire("@/assets/img/nav/1.png"),
    imgSrc: "",
    subTitle: "",
    section: "google-map",
    svgSrc: "",
    offset: -50,
    mobileOffset: 0
  },
];
