var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section4"},[_c('div',{staticClass:"timeline-title",attrs:{"data-aos":"flip-up","data-aos-duration":"1000"}},[_c('span',{staticClass:"en"},[_vm._v("CHRONOLOGY ")]),(_vm.isMobile)?_c('br'):_vm._e(),_vm._v("太平洋建設作品年表\n  ")]),_c('div',{staticClass:"timelines-box",attrs:{"id":"timelines-box"}},[_c('div',{staticClass:"timelines"},_vm._l((_vm.timelines),function(timeline,i){return _c('div',{key:i,staticClass:"timeline-item",class:!timeline.below ? 'upside' : 'downside'},[_c('span',{staticClass:"dot"}),_c('span',{staticClass:"line"}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"year"},[_vm._v(_vm._s(timeline.year))]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(timeline.title))]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(timeline.subtitle))])]),_c('img',{staticClass:"cover",attrs:{"loading":"lazy","src":timeline.img,"alt":"","srcset":""}})])}),0)]),(_vm.isMobile)?_c('div',{staticClass:"bg"}):_vm._e(),_c('div',{staticClass:"yt-frame"},[_c('youtube',{ref:"youtube",staticClass:"player",attrs:{"id":"yt-player","video-id":'PIYp7Q0gWbk',"allowfullscreen":"","player-vars":{
        autoplay: 1,
        loop: 1,
        playlist:'PIYp7Q0gWbk',
        controls: 1,
        showinfo: 0,
        autohide: 1,
        modestbranding: 1,
        rel: 0,
        mute: 0,
        suggestedQuality: 'default',
        iv_load_policy: 3,
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }